<template>
  <div class="sider_container">
    <div class="pc">
      <!-- <div class="top_box">
        <img src="@/assets/images/sider_img.png" alt="" class="sider_img">
        <div class="consult" @click="router.push('/experience')">立即咨询</div>
      </div> -->


      <router-link target="_blank" class="top_box" :to="{path:'/experience'}">
        <img src="@/assets/images/sider_img.png" alt="" class="sider_img">
        <div class="consult" @click="router.push('/experience')">立即咨询</div>
      </router-link>
      <div class="bottom_box">
        <!-- <div class="free content_box" @click="router.push('/experience')">
          
          
        </div> -->


        <router-link target="_blank" class="free content_box" :to="{path:'/experience'}">
          <img src="@/assets/images/free_icon.png" alt="" class="icons">
          <span>免费试用</span>
        </router-link>


        <router-link target="_blank" class="resolve content_box" :to="{path:'/experience'}">
          <img src="@/assets/images/resolve_icon.png" alt="" class="icons">
          <span>定制解决方案</span>
           
        </router-link>


        <!-- <div class="resolve content_box" @click="router.push('/experience')">
          <img src="@/assets/images/resolve_icon.png" alt="" class="icons">
          <span>定制解决方案</span>
        </div> -->
        <div class="top content_box" @click="toTop">
          <img src="@/assets/images/top_icon.png" alt="" class="icons">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useRouter } from 'vue-router'
const router = useRouter()
function toTop() {
  let top = document.documentElement.scrollTop
  const timeTop = setInterval(() => {
    top -= 100;
    document.documentElement.scrollTop = top  
    if (top <= 0) {
      clearInterval(timeTop);
    }
  }, 10);
}
</script>

<style lang="scss" scoped>
.sider_container {
  position: fixed;
  right: 24px;
  bottom: 67px;
  width: 74px;
  z-index: 1000;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  color: #FFFFFF;
  text-align: center;
  .top_box {
    width: 70px;
    height: 70px;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .sider_img {
      width: 70px;
      height: 70px;
    }
    .consult {
      position: absolute;
      bottom: -8px;
      width: 74px;
      height: 25px;
      line-height: 25px;
      background: #006CFF;
      border-radius: 4px 4px 4px 4px;
    }
  }
  .bottom_box {
    width: 74px;
    height: 170px;
    background: #006CFF;
    border-radius: 4px 4px 4px 4px;
    margin-top: 18px;
    .free {
      height: 70px;
      position: relative;
      &::after {
        position: absolute;
        bottom: 0px;
        content: '';
        width: 74px;
        height: 1px;
        background: #FFFFFF;
        border-radius: 0px 0px 0px 0px;
        opacity: 0.3;
      }
    }
    .content_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .icons {
      width: 24px;
      height: 24px;
    }
    .resolve {
      height: 67px;
      position: relative;
      &::after {
        position: absolute;
        bottom: 0px;
        content: '';
        width: 74px;
        height: 1px;
        background: #FFFFFF;
        border-radius: 0px 0px 0px 0px;
        opacity: 0.3;
      }
    }
    .top {
      height: 31px;
    }
  }
}
</style>
