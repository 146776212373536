<template>
  <el-footer class="footer_container">
    <div class="pc">
      <div class="contact_wrap">
        <img src="@/assets/images/footer_bg.png" class="bg_img" alt="">
        <div class="contact_box">
          <div class="footer_title">期待与您的合作</div>
          <div class="scan_code_box flex">
            <img src="@/assets/images/footer_service.png" class="service_img" alt="">
            <div class="right_box">
              <img src="@/assets/images/footer_msg.png" class="msg_img" alt="">
              <div class="right_content flex">
                <div class="info">
                  <div class="counselor">马上扫码添加专属顾问</div>
                  <div class="call">或电话咨询/联系：13570565636</div>
                  <!-- <div class="btn" id="btn-size">免费体验</div> -->
                  <router-link target="_blank" class="btn" id="btn-size" :to="{path:'/experience'}">免费体验</router-link>
                </div>
                <div class="code">
                  <img src="@/assets/images/code.png" class="code_img" alt="">
                </div>
              </div>
            </div>
            <!-- <div class="info">
              <div>马上扫码添加专属顾问</div>
              <div>或电话咨询/联系：400-8123-123</div>
              <div class="btn">免费体验</div>
            </div>
            <div class="code">
              <img src="@/assets/images/code.png" class="code_img" alt="">
            </div> -->
          </div>
        </div>
      </div>
      <div class="footer_wrap">
        <div class="type_center">
          <div class="tab_list flex">
            <div class="tab_item flex">
              <img src="@/assets/images/footer_icon1.png" class="icon" alt="">
              <span>7*24小时安全保障</span>
            </div>
            <div class="tab_item flex">
              <img src="@/assets/images/footer_icon2.png" class="icon" alt="">
              <span>专业技术服务支持</span>
            </div>
            <div class="tab_item flex">
              <img src="@/assets/images/footer_icon3.png" class="icon" alt="">
              <span>多种解决方案提供</span>
            </div>
            <!-- <div class="tab_item flex">
              <img src="@/assets/images/footer_icon4.png" class="icon" alt="">
              <span>广东省高新技术企业</span>
            </div> -->
          </div>
          <div class="line"></div>
          <div class="bottom_box flex">
            <div class="left_box flex">
              <img src="@/assets/images/footer_logo.png" class="logo_img" alt="">
              <img src="@/assets/images/footer_code.png" class="code_img" alt="">
            </div>
            <div class="right_box flex">
              <div class="intro_item">
                <div class="title">产品中心</div>
                <div class="flex intro_bottom">
                  <ul>
                    <li @click="router.push('/school')" class="cusors">派学堂网校</li>
                    <li @click="router.push('/crm')" class="cusors">派学堂CRM</li>
                    <li @click="router.push('/administration')" class="cusors">派学堂教务</li>
                    <li @click="router.push('/learn')" class="cusors">派学堂学习中心</li>
                    
                  </ul>
                  <!-- <ul>
                    <li>供应链服务</li>
                    <li>社交内容平台</li>
                    <li>学员运营</li>
                    <li>师资课程供应</li>
                    <li>渠道流量供应</li>
                  </ul> -->
                </div>
              </div>
              <div class="intro_item">
                <div class="title">解决方案</div>
                <div class="flex intro_bottom">
                  <ul>
                    <li @click="router.push('/resolve')" class="cusors">方案A：CRM+网校</li>
                    <li @click="router.push('/resolve')" class="cusors">方案B：网校+教务</li>
                    <li @click="router.push('/resolve')" class="cusors">方案C：CRM+网校+教务</li>
                  </ul>
                  <!-- <ul>
                    <li>场景支持</li>
                    <li>招生引流</li>
                    <li>直播教学</li>
                    <li>服务工单</li>
                    <li>考试刷题</li>
                  </ul> -->
                </div>
              </div>
              <div class="intro_item">
                <div class="title">联系我们</div>
                <div class="flex intro_bottom">
                  <ul>
                    <li class="phone">13570565636</li>
                    <!-- <li>商业合作</li>
                    <li>意见反馈</li> -->
                  </ul>
                </div>
              </div>

            </div>
          </div>
          <div class="line"></div>
          <div class="copy_right flex">
            <div>Copyright © 2023 www.paixuetang.com</div>
            <img src="@/assets/images/record.png" alt="">
            <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023007883号 -1 </a>&nbsp;&nbsp;
            <div>All Rights Reserved Powered by 广州派学堂信息技术有限公司</div>
          </div>
        </div>
      </div>
    </div>
    <div class="h5">
      <div class="contact_h5_wrap">
        <div class="footer_h5_title">期待与您的合作</div>
        <div class="flex">
          <img src="@/assets/images/h5_footer_service.png" class="h5_service_img" alt="">
          <img src="@/assets/images/h5_footer_code.png" class="h5_code_img" alt="">
        </div>
        <div class="h5_counselor">马上扫码添加专属顾问</div>
        <div class="h5_call">或电话咨询/联系：13570565636</div>
      </div>
      <div class="footer_h5_wrap">
        <div class="title_h5_second">服务热线</div>
        <div class="title">13570565636</div>
        <el-collapse>
          <el-collapse-item title="产品中心" name="1">
            <div class="title_h5_second" @click="router.push('/school')">派学堂网校</div>
            <div class="title_h5_second" @click="router.push('/crm')">派学堂CRM</div>
            <div class="title_h5_second" @click="router.push('/administration')">派学堂教务</div>
            <div class="title_h5_second" @click="router.push('/learn')">派学堂学习中心</div>
          </el-collapse-item>
          <el-collapse-item title="解决方案" name="2">
            <div class="title_h5_second" @click="router.push('/resolve')">营销+网校+教务</div>
          </el-collapse-item>
          <el-collapse-item title="关于我们" @click="router.push('/about')">
            <!-- <div class="title_h5_second">简化流程：设计简洁直观的操作流程；</div> -->
          </el-collapse-item>
        </el-collapse>
        <div class="copy_right">
          <div>Copyright © 2023 www.paixuetang.com</div>
          <div>All Rights Reserved Powered 派学堂 版权所有</div>
        </div>
      </div>
    </div>
  </el-footer>
</template>

<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()
</script>

<style lang="scss" scoped>
.footer_container {
  height: auto !important;
  width: 100%;
  background: #11306B;
  font-family: PingFang HK-Medium, PingFang HK;
  .pc {
    .contact_wrap {
      position: relative;
      width: 100%;
      height: 348px;
      .bg_img {
        height: 100%;
        width: 100%;
      }
      .contact_box {
        position: absolute;
        width: 100%;
        top: 30px;
        text-align: center;
        .footer_title {
          font-size: 40px;
          color: #FFFFFF;
          height: 56px;
        }
        .scan_code_box {
          // height: 215px;
          justify-content: center;
          .service_img {
            width: 160px
          }
          .right_box {
            width: 462px;
            height: 172px;
            position: relative;
            .msg_img {
              width: 100%;
            }
            .right_content {
              position: absolute;
              top: 0px;
              padding: 25px 32px 0 42px;
              .info {
                color: #fff;
                .counselor {
                  font-size: 24px;
                  color: #FFFFFF;
                  margin-bottom: 7px;
                }
                .call {
                  font-size: 16px;
                  margin-bottom: 17px;
                }
                .btn {
                  width: 136px;
                  height: 40px;
                  line-height: 40px;
                  background: #FF9900;
                  border-radius: 2px 2px 2px 2px;
                  text-align: center;
                  background: #F6B100;
                }
              }
              .code {
                margin-left: 20px;
                .code_img {
                  width: 120px;
                  height: 120px;
                }
              }
            }
          }
        }
      }
      .card_wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        margin-left: 50px;
        // top: 138px;
        // left: 818px;
        .info {
          color: #fff;
          div {
            &:nth-child(1) {
              font-size: 1VW;
              // font-size: 24px;
            }
            &:nth-child(2) {
              font-size: 16px;
              margin: 7px 0px 17px 0px;
            }
          }
          .btn {
            width: 136px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            background: #F6B100;
            border-radius: 2px 2px 2px 2px;
          }
        }
        .code {
          margin-left: 20px;
          .code_img {
            width: 120px;
            height: 120px;
          }
        }
      }
    }
    .footer_wrap {
      .tab_list {
        height: 88px;
        .tab_item {
          position: relative;
          justify-content: start;
          flex: 1;
          font-size: 16px;
          color: #fff;
          &:nth-child(-n+2) {
            margin-right: 74px;
            &::after {
              position: absolute;
              right: 0px;
              top: 50%;
              transform: translateY(-50%);
              content: '';
              width: 2px;
              height: 56px;
              background: linear-gradient(180deg, rgba(236,236,236,0) 0%, #ECECEC 50%, rgba(236,236,236,0) 100%);
              border-radius: 0px 0px 0px 0px;
              opacity: 0.3;
            }
          }
          .icon {
            width: 40px;
            height: 40px;
          }
          span {
            margin-left: 14px;
          }
          
        }
      }
      .line {
        width: 1200px;
        height: 1px;
        background: #ECECEC;
        opacity: 0.1;
      }
      .bottom_box {
        color: #fff;
        margin-top: 17px;
        margin-bottom: 12px;
        justify-content: start;
        align-items: self-start;
        .left_box {
          flex-direction: column;
          margin-right: 218px;
          .logo_img {
            width: 103px;
            height: 30px;
            margin-bottom: 13px;
          }
          .code_img {
            width: 92px;
            height: 92px;
          }
        }
        .right_box {
          flex: 1;
          align-items: flex-start;
          .intro_item {
            width: 212px;
            .title {
              font-size: 16px;
              margin-bottom: 16px;
            }
            .intro_bottom {
              li {
                margin-bottom: 6px;
                font-size: 14px;
              }
              .phone {
                font-size: 28px;
                margin-bottom: 18px;
              }
            }
          }
        }
      }
      .copy_right {
        justify-content: center;
        color: #fff;
        height: 49px;
        font-size: 12px;
        img {
          width: 16px;
          height: 16px;
          margin: 0 4px 0px 8px;
        }
      }
    }
  }
  .h5 {
    .contact_h5_wrap {
      padding: 16px 32px 13px;
      background-image: url('@/assets/images/footer_bg.png');
      text-align: center;
      .footer_h5_title {
        height: 28px;
        line-height: 28px;
        font-size: 20px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        margin-bottom: 16px;
      }
      .h5_service_img {
        width: 120px;
      }
      .h5_code_img {
        width: 164px;
      }
      .h5_counselor {
        height: 20px;
        line-height: 20px;
        font-size: 0.4rem;
        color: #FFFFFF;
        margin: 9px 0 5px 0;
      }
      .h5_call {
        height: 20px;
        line-height: 20px;
        font-size: 0.4rem;
        color: #FFFFFF;
        padding-left: 2rem;
      }
    }
    .footer_h5_wrap {
      padding: 20px 16px 8px;
      text-align: left;
      color: #fff;
      .title_h5_second {
        font-size: 0.4rem;
        color: #fff;
        height: 20px;
        line-height: 20px;
        margin-top: 8px;
      }
      .title {
        font-size: 28px;
        height: 36px;
        line-height: 36px;
        margin: 9px 0px;
      }
    }
    .copy_right {
      font-size: 12px;
      text-align: center;
      margin-top: 24px;
      div {
        height: 17px;
        line-height: 17px;
        &:first-child {
          margin-bottom: 2px;
        }
      }
    }
  }
}
.cusors{
  cursor: pointer;
}
.cusors:hover{
  text-decoration: underline;
}



</style>
