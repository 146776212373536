import { createRouter, createWebHistory } from 'vue-router'
import layout from '@/layouts/index.vue'
const routes = [
  {
    path: '/',
    component: layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: () => import('@/views/home/index.vue'),
      },
      {
        path: 'crm',
        component: () => import('@/views/crm/index.vue'),
      },
      {
        path: 'school',
        component: () => import('@/views/school/index.vue'),
      },
      {
        path: 'administration',
        component: () => import('@/views/administration/index.vue'),
      },
      {
        path: 'learn',
        component: () => import('@/views/learn/index.vue'),
      },
      {
        path: 'product',
        component: () => import('@/views/product/index.vue'),
      },
      {
        path: 'plan',
        component: () => import('@/views/plan/index.vue'),
      },
      {
        path: 'about',
        component: () => import('@/views/about/index.vue'),
      },
      {
        path: 'resolve',
        component: () => import('@/views/resolve/index.vue'),
      },
    ]
  },
  {
    path: '/experience',
    component: () => import('@/views/experience/index.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: (to, from) => {
    return {
      top: 0
    }
  },
  routes
})

// router.beforeEach((to, from, next) => {
//   if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
//     if (to.path.indexOf('/mobile') === -1) {
//       if(to.path.indexOf('/pc') !== -1) {
//         next({path: to.path.replace('/pc', '/mobile')})
//       } else {
//         next({path: '/mobile'+to.path})
//       }
//     } else {
//       next()
//     }
//   } else {
//     if (to.path.indexOf('/pc') === -1) {
//       if(to.path.indexOf('/mobile') !== -1) {
//         next({path: to.path.replace('/mobile', '/pc')})
//       } else {
//         next({path: '/pc'+to.path})
//       }
//     } else {
//       next()
//     }
//   }  
//  })

export default router
