<template>
  <el-header class="header_container">
    <div class="pc" :class="[whiteBg || isShow ? 'white_Bg' : '']">
      <div class="header_wrap type_center flex">
        <div class="header_left flex">
          <h1 class="link">
            <img src="@/assets/images/logo.png" alt="">
          </h1>
          <ul class="nav_container flex">
            <li @click="handleClick('/home')" :class="['link', active === 'home' ? 'homeactive' : 'home-nomal']">首页</li>
            <li class="link product" :class="[['crm', 'school', 'learn', 'administration'].includes(active) ? 'homeactive' : '']"
              @mouseenter="isShow = true" @mouseleave="isShow = false">
              <div>产品中心</div>
              <div class="drop_down" :class="[isShow ? 'active_drop_down' : '']">
                <div class="type_center">
                  <div class="title">SaaS服务</div>
                  <div class="flex">
                    <div :class="['product_item', 'flex', activeProduct === 'crm' ? 'product_active' : '']"
                      @click="handleProductClick('crm')">
                      <img src="@/assets/images/pc-log1.png" alt="" class="pc-log">
                      <div class="intro">
                        <p>派学堂CRM</p>
                        <p class="bottom_intro">精准拓客，高效转化</p>
                      </div>
                    </div>
                    <div :class="['product_item', 'flex', activeProduct === 'school' ? 'product_active' : '']"
                      @click="handleProductClick('school')">
                      <img src="@/assets/images/pc-log2.png" alt="" class="pc-log">
                      <div class="intro">
                        <p>派学堂网校</p>
                        <p class="bottom_intro">小白能快速上线运营的教育平台</p>
                      </div>
                    </div>
                    <div :class="['product_item', 'flex', activeProduct === 'administration' ? 'product_active' : '']"
                      @click="handleProductClick('administration')">
                      <img src="@/assets/images/pc-log3.png" alt="" class="pc-log">
                      <div class="intro">
                        <p>派学堂教务</p>
                        <p class="bottom_intro">全域精细化运营学员</p>
                      </div>
                    </div>
                    <div :class="['product_item', 'flex', activeProduct === 'learn' ? 'product_active' : '']"
                      @click="handleProductClick('learn')">
                      <img src="@/assets/images/pc-log4.png" alt="" class="pc-log">
                      <div class="intro">
                        <p>派学堂学习</p>
                        <p class="bottom_intro">高质量社区，陪伴式学习</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li @click="handleClick('/resolve')" :class="['link', active === 'resolve' ? 'homeactive' : '']">解决方案</li>
            <li @click="handleClick('/about')" :class="['link', active === 'about' ? 'homeactive' : '']">关于我们</li>
          </ul>
        </div>
        <div class="header_right flex">
          <!-- <div class="free_btn link" @click="router.push('/experience')">免费体验</div> -->
          <router-link target="_blank" class="free_btn link" :to="{path:'/experience'}">免费体验</router-link>
        </div>
      </div>
    </div>
    <div class="h5">
      <div class="header_h5_wrap">
        <h1 class="link">
          <img src="@/assets/images/logo.png" alt="">
        </h1>
        <div class="flex">
          <router-link target="_blank" class="free_btn link" :to="{path:'/experience'}">免费体验</router-link>
          <img v-if="!showMenu" src="@/assets/images/h5_header_more.png" class="more" alt="" @click="showMenu = true">
          <img v-else src="@/assets/images/h5_header_close.png" class="more" alt="" @click="showMenu = false">
        </div>
      </div>
      <div class="menu-page" :class="[showMenu ? 'showMenu' : 'hideMenu']">
        <el-menu router :default-active="activeIndex" class="el-menu-demo" @select="showMenu = false"
          background-color="transparent" text-color="#666" active-text-color="#037dff">
          <el-menu-item index="/" class="home-nomal">首页</el-menu-item>
          <el-sub-menu index="/1">
            <template #title><div class="home-nomal">产品中心</div></template>
            <div class="pc">
              <el-menu-item-group>
                <template #title>SaaS服务</template>
                <div class="divide_line"></div>
                <el-menu-item index="/crm">派学堂CRM</el-menu-item>
                <el-menu-item index="/school">派学堂网校</el-menu-item>
                <el-menu-item index="/administration">派学堂教务</el-menu-item>
                <el-menu-item index="/learn">派学堂学习</el-menu-item>
              </el-menu-item-group>
            </div>
            <div class="h5">
              <el-menu-item-group>

                <div class="menu-flex">

                  <el-menu-item index="/crm">
                    <div>
                      <img src="@/assets/images/CRM.png" alt="" class="logo-images">
                      <p>CRM</p>

                    </div>
                  </el-menu-item>
                  <el-menu-item index="/school">
                    <div>
                      <img src="@/assets/images/online-school.png" alt="" class="logo-images">
                      <p>网校</p>

                    </div>
                  </el-menu-item>
                  <el-menu-item index="/administration">
                    <div>
                      <img src="@/assets/images/educational.png" alt="" class="logo-images">
                      <p>教务</p>

                    </div>
                  </el-menu-item>
                  <el-menu-item index="/learn">
                    <div>
                      <img src="@/assets/images/study.png" alt="" class="logo-images">
                      <p>学习</p>

                    </div>
                  </el-menu-item>
                </div>

              </el-menu-item-group>
            </div>

          </el-sub-menu>
          <!-- <el-sub-menu index="/2">
            <template #title>产品中心</template>
            <el-menu-item-group>
              <template #title>企业</template>
              <el-menu-item index="/aboutUs">教育机构</el-menu-item>
              <el-menu-item index="/aboutUs">高等院校</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group>
              <template #title>场景支持</template>
              <el-menu-item index="/aboutUs">教育机构</el-menu-item>
              <el-menu-item index="/aboutUs">高等院校</el-menu-item>
            </el-menu-item-group>
          </el-sub-menu> -->
          <el-menu-item index="/resolve" class="home-nomal">解决方案</el-menu-item>
          <el-menu-item index="/about" class="home-nomal">关于我们</el-menu-item>
        </el-menu>
      </div>
    </div>
  </el-header>
</template>

<script setup>
import { reactive, ref, onMounted, onUnmounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
const router = useRouter()
const route = useRoute()

const active = ref('home')
const handleClick = val => {
  activeProduct.value = ''
  router.push(val)
}

const activeProduct = ref('')
const isShow = ref(false) //是否展示产品中心下拉
const handleProductClick = val => {
  activeProduct.value = val
  active.value = val
  isShow.value = false
  router.push('/' + val)
}

const showMenu = ref(false) //h5是否展示banner选择


const whiteBg = ref(false) //背景是否白色
const handleScroll = () => {
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
  if (scrollTop > 0) {
    whiteBg.value = true
  } else {
    whiteBg.value = false
  }
}
onMounted(() => {
  let path = route.path.split('/')[1]
  active.value = path
  activeProduct.value = path
  window.addEventListener('scroll', handleScroll)
})
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
watch(() => route.path, val => {
  let path = route.path.split('/')[1]
  active.value = path
  activeProduct.value = path
})
const activeIndex = ref()

</script>

<style lang="scss" scoped>
.header_container {
  position: fixed;
  width: 100vw;
  height: 64px;
  z-index: 1000;

  .pc {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.4);

    .header_wrap {
      height: 100%;

      .header_left {
        height: 100%;
        flex: 1;
        justify-content: start;

        .nav_container {
          margin-left: 150px;

          li {
            font-size: 16px;
            color: #16181A;
            padding: 20px;
          }

          .homeactive {
            color: #006CFF
          }

          .product {
            .drop_down {
              position: fixed;
              top: 64px;
              left: 0px;
              height: 0px;
              box-shadow: 0px 6px 12px rgba(22, 24, 26, 0.03);
              overflow: hidden;
              width: 100vw;
              background-color: #fff;
              transition: all .5s;

              .title {
                margin-top: 16px;
                margin-bottom: 8px;
                color: #5C6F93;
              }

              .product_item {
                width: 268px;
                height: 80px;
                padding: 16px 0 0 16px;
                justify-content: flex-start;
                align-items: flex-start;

                img {
                  width: 18px;
                  margin-right: 14px;
                }

                .intro {
                  font-size: 16px;
                  color: #11306B;

                  .bottom_intro {
                    font-size: 13px;
                    color: #5C6F93;
                    margin-top: 8px;
                  }
                }
              }

              .product_active {
                background: url('@/assets/images/product_selected.png');
                background-size: 100%;
              }

            }

            .active_drop_down {
              display: block;
              height: 136px;
            }
          }
        }
      }

      .header_right {
        .free_btn {
          width: 100px;
          text-align: center;
          height: 40px;
          line-height: 40px;
          left: 0px;
          top: 0px;
          background: #006CFF;
          border-radius: 2px;
          font-size: 14px;
          color: #FFFFFF;

          &:hover {
            background: linear-gradient(134deg, #006CFF 0%, #00C1FE 48%, #00BBFD 100%);
          }
        }
      }
    }
  }

  .h5 {
    .free_btn{
      width: 100px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    left: 0px;
    top: 0px;
    background: #006CFF;
    border-radius: 2px;
    font-size: 14px;
    color: #FFFFFF;
    }
    .header_h5_wrap {
      height: 64px;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;

      .more {
        width: 24px;
        height: 24px;
        margin-left: 24px;
      }
    }

    .menu-page {
      position: fixed;
      right: 0;
      top: 64px;
      left: -1000px;
      width: 100vw;
      height: 0px;
      background: #fff;
      z-index: 10000;
      color: #fff;
      transition: height .5s;
      overflow: scroll;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f5f5f5;
        padding: 16px 4.8vw;

        .close {
          width: 24px;
          height: 24px;
          margin-left: 24px;
        }
      }
    }

    .showMenu {
      height: 100vh;
      left: 0;
    }

    .hideMenu {
      height: 0px;
      left: -1000px;
    }


    .el-menu-item,
    .el-submenu__title {
      font-size: 16px;
    }

    .btn {
      width: 80px;
      text-align: center;
      height: 28px;
      font-size: 12px;
      color: #FFFFFF;
      line-height: 28px;
      background: #006CFF;
      border-radius: 2px 2px 2px 2px;
    }
  }

}

.white_Bg {
  background-color: #fff !important;
  box-shadow: rgba(6, 101, 75, 0.15) 0px 3px 7px 0px;
}

@media(max-width:500px) {
  .menu-flex {
    display: flex;
    flex-wrap: nowrap;

  }

  .menu-flex>li {
    width: 25%;
    margin-left: 0px;
    padding-left: 0.2rem !important;
    padding-right: 0px !important;
    text-align: center;
    margin: 0px auto !important;
  }

  .el-menu-item {
    display: block !important;

  }

  .el-sub-menu .el-menu-item {
    height: inherit !important;
    line-height: inherit !important;
  }

  .logo-images {
    width: 0.6rem;
    height: 0.6rem;
    margin-top: 0rem;
  }
  .menu-flex p{
    margin-top: 0.1rem !important;
  }
  .home-nomal{
  font-weight: bold;
}

}
.pc-log{
  width: 48px !important;
  height: 48px !important;
}

</style>
<style>@media(max-width:500px) {
  .el-menu-item-group__title {
    padding: 0rem 0rem 0.07rem 0rem !important;
  }
}</style>
